<template>
	<div class="page">
		<div v-if="loading">
			<h4>Loading...</h4>
		</div>

		<div v-if="plan">
			<TextInput label="Plan Name" v-model="plan.name" />

			<div class="plan-schedule" v-if="active">
				<h3>Current Schedule</h3>
				<h4>{{ active.schedule.name }}</h4>
			</div>

			<h3>Schedules</h3>
			<div
				class="plan-schedule"
				v-for="(schedule, i) in schedules"
				:key="i"
			>
				<div>
					<TextInput
						:value="schedule.name"
						@input="(e) => setScheduleName(schedule.id, e)"
					/>
				</div>

				<div>
					<button @click="() => (activeScheduleId = schedule.id)">
						<i class="fal fa-fw fa-check"></i>
					</button>
					<button @click="() => removeScheduleOption(i)">
						<i class="fal fa-fw fa-trash"></i>
					</button>
				</div>

				<div class="days">
					<a
						href="#"
						@click.prevent="setSelectedDay(schedule.id, day, j)"
						class="plan-schedule__day"
						v-for="(day, j) in schedule.days"
						:key="j"
					>
						<div class="container">
							<div
								class="plan-schedule__task"
								v-for="(task, k) in day"
								:key="k"
							>
								{{ task.name }}
							</div>
						</div>
					</a>
					<a
						href="#"
						class="plan-schedule__button"
						@click.prevent="() => addScheduleDay(schedule.id)"
						><i class="fal fa-fw fa-plus"></i
					></a>
				</div>
			</div>
		</div>

		<button v-if="plan" @click="addSchedule">Add Schedule</button>

		<SideMenu
			:open="activeScheduleId != null"
			@close="() => (activeScheduleId = null)"
		>
			<div v-if="activeScheduleId">
				<h4>Set Active Schedule</h4>

				<h5>Shift Schedule</h5>
				<button @click="() => shiftDays--">
					<i class="fal fa-fw fa-angle-left"></i>
				</button>
				<button @click="() => (shiftDays = 0)">
					<i class="fal fa-fw fa-circle"></i>
				</button>
				<button @click="() => shiftDays++">
					<i class="fal fa-fw fa-angle-right"></i>
				</button>

				<div class="plan-schedule">
					<div class="days">
						<span
							v-for="(_, i) in activeScheduleDays.length"
							:key="i"
							:class="[
								'plan-schedule__day',
								{
									first:
										mod(
											i + shiftDays,
											activeScheduleDays.length
										) == 0,
								},
							]"
						>
							<div class="title">
								{{ getDayText("ddd, DD", dayStart, i) }}
							</div>
							<div class="container">
								<div>
									Day
									{{
										mod(
											i + shiftDays,
											activeScheduleDays.length
										) + 1
									}}
								</div>
								<div
									class="plan-schedule__task"
									v-for="(task, k) in activeScheduleDays[
										mod(
											i + shiftDays,
											activeScheduleDays.length
										)
									]"
									:key="k"
								></div>
							</div>
						</span>
						<span class="plan-schedule__button">
							<i class="fal fa-fw fa-ellipsis-h"></i>
						</span>
					</div>
				</div>

				<button @click="setScheduleActive">Save</button>
			</div>
		</SideMenu>

		<SideMenu
			:open="selectedDay != null"
			@close="() => (selectedDay = null)"
		>
			<ul>
				<li>
					<a
						class="menu-item"
						href="#"
						@click.prevent="
							() =>
								removeScheduleDay(
									selectedScheduleId,
									selectedDayIdx
								)
						"
					>
						<i class="menu-icon fal fa-fw fa-trash"></i>
						<span class="menu-title">Remove</span>
					</a>
				</li>
			</ul>
			<h4>Toggle Tasks</h4>
			<ul v-if="selectedDay != null">
				<li v-for="(task, i) in taskOptions" :key="i">
					<a
						href="#"
						:class="['menu-item', { active: task.active }]"
						@click.prevent="() => toggleTask(task)"
						>{{ task.name }}</a
					>
				</li>
			</ul>
		</SideMenu>

		<div class="action-bar-container">
			<div class="action-bar-row">
				<div class="action-bar">
					<button>
						<router-link to="/board"
							><i class="fal fa-fw fa-arrow-left"></i
						></router-link>
					</button>
					<button @click="save">
						<i class="fal fa-fw fa-save"></i>
						<label>Save</label>
					</button>
					<a class="btn" href="#" @click.prevent="addSchedule">
						<i class="fal fa-fw fa-calendar-alt"></i>
						<label>Add Schedule</label>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SideMenu from "@/components/menus/SideMenu";
import { TextInput } from "@/components/inputs/index";
import { mod } from "@/helpers/math";

import dayjs from "dayjs";

const planDefault = JSON.stringify({
	name: "New Plan",
	history: [],
	scheduleOptions: [],
	schedules: {},
	nextScheduleId: 1,
});

export default {
	name: "Plan",
	components: {
		SideMenu,
		TextInput,
	},
	props: {
		id: String,
	},
	data: () => ({
		loading: false,
		plan: null,
		schedules: [],
		selectedScheduleId: "",
		selectedDay: null,
		selectedDayIdx: -1,
		selectedDayIds: [],
		shiftDays: 0,
		activeScheduleId: null,
	}),
	computed: {
		...mapGetters({
			tasks: "tasks/data",
			plans: "plans/data",
		}),
		taskOptions() {
			return this.tasks.list.map((task) => ({
				...task,
				active: this.selectedDayIds.includes(task._id),
			}));
		},
		dayStart() {
			const now = Date.now();
			const day = dayjs(now);
			const today = day.format("YYYY-MM-DD");
			const start = dayjs(today + "T00:00:00").unix() * 1000;
			return start;
		},
		activeScheduleDays() {
			if (!this.activeScheduleId) return [];

			return this.plan.schedules[this.activeScheduleId].days;
		},
		active() {
			if (!this.plan) return null;

			const count = (this.plan.history || []).length;

			if (!count) return null;

			const history = this.plan.history[count - 1];
			const schedule = this.plan.schedules[history.scheduleId];

			return {
				schedule,
				history,
			};
		},
	},
	async created() {
		this.load();
	},
	watch: {
		"$route.path"() {
			this.load();
		},
	},
	methods: {
		...mapActions({
			listTasks: "tasks/listThrottled",
			listPlansThrottled: "plans/listThrottled",
			createPlan: "plans/create",
			updatePlan: "plans/update",
			success: "broadcastSuccess",
		}),
		async load() {
			this.loading = true;
			await this.listPlansThrottled();
			await this.listTasks();

			if (this.id)
				this.plan = {
					...JSON.parse(planDefault),
					...this.plans.getFromId(this.id),
				};
			else this.plan = JSON.parse(planDefault);

			if (this.plan) this.mapSchedules();

			this.loading = false;
		},
		async save() {
			this.loading = true;
			if (!this.id) {
				await this.createPlan(this.plan);
				this.success("Created!");
				this.$router.push("/board");
			} else {
				await this.updatePlan({ id: this.id, data: this.plan });
				this.success("Updated!");
			}
			this.loading = false;
		},
		toggleTask(task) {
			const idx = this.selectedDayIds.findIndex((id) => task._id == id);
			if (idx >= 0)
				this.plan.schedules[this.selectedScheduleId].days[
					this.selectedDayIdx
				].splice(idx, 1);
			else
				this.plan.schedules[this.selectedScheduleId].days[
					this.selectedDayIdx
				].push(task._id);

			this.mapSchedules();
		},
		setSelectedDay(scheduleId, day, dayIdx) {
			this.selectedScheduleId = scheduleId;
			this.selectedDay = day;
			this.selectedDayIdx = dayIdx;
			this.selectedDayIds = this.selectedDay.map((x) => x._id);
		},
		mapSchedules() {
			this.schedules = this.plan.scheduleOptions.map((option) => {
				const schedule = this.plan.schedules[option.id];
				const days = schedule.days.map((day) => {
					return day.map((id) => this.tasks.getFromId(id));
				});

				return {
					id: option.id,
					name: schedule.name,
					days,
				};
			});

			if (this.selectedDay != null) {
				this.setSelectedDay(
					this.selectedScheduleId,
					this.plan.schedules[this.selectedScheduleId].days[
						this.selectedDayIdx
					].map((id) => this.tasks.getFromId(id)),
					this.selectedDayIdx
				);
			}
		},
		addSchedule() {
			const id = `sch_${this.plan.nextScheduleId++}`;

			this.plan.schedules[id] = {
				name: id,
				days: [[]],
			};

			this.plan.scheduleOptions.push({ id });

			this.mapSchedules();
		},
		addScheduleDay(scheduleId) {
			this.plan.schedules[scheduleId].days.push([]);
			this.mapSchedules();
		},
		setScheduleName(scheduleId, value) {
			this.plan.schedules[scheduleId].name = value;
			this.mapSchedules();
		},
		removeScheduleDay(scheduleId, index) {
			this.selectedDay = null;
			const schedule = this.plan.schedules[scheduleId];
			if (schedule.days.length == 1) {
				schedule.days[0] = [];
				return;
			}

			schedule.days.splice(index, 1);
			this.mapSchedules();
		},
		getDayText(format, dateStartMillis, idx) {
			const day = dayjs(dateStartMillis).add(idx, "day");
			return day.format(format);
		},
		setScheduleActive() {
			let count = (this.plan.history || []).length;
			if (
				count > 0 &&
				this.plan.history[count - 1].date == this.dayStart
			) {
				this.plan.history[count - 1].scheduleId = this.activeScheduleId;
				this.plan.history[count - 1].scheduleOffset = this.shiftDays;
			} else {
				this.plan.history.push({
					date: this.dayStart,
					scheduleId: this.activeScheduleId,
					scheduleOffset: this.shiftDays,
				});
			}
			this.activeScheduleId = null;
		},
		removeScheduleOption(idx) {
			this.plan.scheduleOptions.splice(idx, 1);
			this.mapSchedules();
		},
		mod,
	},
};
</script>
