<template>
	<transition name="dialog-anim">
		<div v-show="active" :class="['side-dialog menu active']" @click.stop>
			<slot />
		</div>
	</transition>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	props: {
		dialogKey: String,
	},
	computed: {
		...mapGetters({
			dialog: "dialog",
		}),
		active() {
			return this.dialog.current == this.dialogKey;
		},
	},
	methods: {
		close() {
			this.$emit("close");
		},
	},
};
</script>
